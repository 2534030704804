<template>
  <v-snackbar
    :value="alertData.isVisible"
    @input="onSnackbarInput($event)"
    bottom
    color="error"
    :timeout="3000"
  >
    <h4 class="d-inline">{{ alertData.message }}</h4>
    <template v-slot:action>
      <v-btn class="ml-2" dark text @click="alertData.isVisible = false">
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: function () {
    return {
      err: null,

      alertData: {
        message: null,
        isVisible: false,
        type: "danger",
      },
    };
  },

  computed: {
    error() {
      return this.$store.state.app.httpError;
    },
  },

  mounted() {
    this.err = this.error;

    this.processError();
  },

  watch: {
    error: function (newError) {
      this.err = newError;

      this.processError();
    },
  },

  methods: {
    onSnackbarInput() {
      this.$store.commit("app/resetHttpError");
    },

    setError(error) {
      this.err = error;

      this.processError();
    },

    processError() {
      if (!this.err) {
        this.alertData.isVisible = false;

        return;
      }

      console.log("houston we have a problem...");

      console.log(this.err);

      if (this.gotError("300")) {
        this.process300();

        return;
      }

      if (this.gotError("401")) {
        this.showAlert('Usuário ou senha inválidos')

       

        this.$router.push("/login");

        return;
      }

      if (this.gotError("403")) {
        this.showAlert("Página bloqueada.");

        return;
      }

      if (this.gotError("419")) {
        this.processExpiredSession();

        return;
      }

      if (this.gotError("422")) {
        console.log("sound's like a 422 error...lets try understand");

        this.process422();

        return;
      }

      if (this.gotError("500")) {
        console.log("wtf, its a 500 error...");

        this.showAlert(
          "Ocorreu um erro no servidor, mas nossa equipe já está sendo alertada."
        );

        return;
      }

      if (this.gotError("503")) {
        console.log("wtf, its a 503 error...");
        this.process503();
        return;
      }

      if (this.gotNetworkError()) {
        this.showAlert(
          "Erro na conexão, cheque sua conexão ou tente mais tarde."
        );

        return;
      }

      this.showAlert("Algo deu errado!");
    },

    gotError(errorCode) {
      if (this.err.response) {
        if (this.err.response.status) {
          return this.err.response.status == errorCode;
        }
      }

      return false;
    },

    processExpiredSession() {
      // todo change to $auth.sessionExpired()
      window.location.href = "/login";
    },

    process300() {
      console.log(this.err.response.data)
      // check is has redirect 

      // 
      //this.showAlert(this.extractErrorMessage());
    },

    process422() {
      this.showAlert(this.extractErrorMessage());
    },
    process503() {
      window.location.href = "/mauntencao";
      this.showAlert("Sistema em manutenção, tente mais tarde.");
    },

    gotNetworkError() {
      return this.err.message == "Network Error";
    },

    extractErrorMessage() {
      let errorMsg = this.err.response.data;

      if (typeof errorMsg == "object") {
        if (Object.keys(errorMsg).length == 0) {
          return "Algo deu errado!";
        }

        if (errorMsg.errors) {
          return errorMsg.errors[Object.keys(errorMsg.errors)[0]][0];
        }

      }

      if (this.err.response.data) {
        return this.err.response.data;
      }

      return "Algo deu errado!";
    },

    showAlert(msg) {
      this.alertData.message = msg;

      this.alertData.isVisible = true;
    },
  },
};
</script>

