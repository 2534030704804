<template>
  <!-- <v-text-field
    v-bind="$attrs"
    hide-details="auto"
    v-on="$listeners"
    dense
    @input="handleInput($event)"
    
  /> -->
  <app-mask-field v-bind="$attrs" mask="##.###.###/####-##" v-on="$listeners" />
</template>

<script>
import VCleaveInput from "vuetify-cleave";
import axios from "axios";

export default {
  components: { VCleaveInput },

  props: {
    search: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loading: false,
    options: {
      delimiters: [".", ".", "/", "-"],
      blocks: [2, 3, 3, 4, 2],
      uppercase: true,
    },
  }),
  methods: {
    handleInput(cnpj) {
      if (this.search) {
        if (cnpj.length == 14) {
          this.searchCnpj(cnpj);
        }
      }
    },
    async searchCnpj(cnpj) {
      this.loading = true;

      await axios
        .get("https://brasilapi.com.br/api/cnpj/v1/" + cnpj)
        .then((response) => {
          console.log(response)
          this.$emit("company", response.data);
        });

      this.loading = false;
    },
  },
};
</script>